export const AUTH_METHODS = {
	LOGIN: 'login',
	SIGNUP: 'register',
}

export const INTERACTIONS = {
	SOCIAL_APP_LOGIN: 'members-social-app-login',
	SOCIAL_APP_LOGIN_WITH_VENDOR: (vendor: string) => `members-social-app-login-${vendor}`,
	DEFAULT_LOGIN: 'members-default-login',
	CODE_LOGIN: 'members-code-login',
	CODE_SIGNUP: 'members-code-signup',
	DEFAULT_SIGNUP: 'members-default-signup',
	RESET_PASSWORD: 'members-reset-password',
	VERIFY_TOKEN: 'apply-session-token',
	EDITOR: {
		CODE_LOGIN: 'editor-members-code-login',
	},
	WELCOME_DIALOG: 'members-welcome-dialog',
	VERIFICATION_CODE: 'verification-code',
}

export const DIALOGS = {
	Login: 'login',
	SignUp: 'register',
	ResetPasswordEmail: 'resetPasswordEmail',
	ResetPasswordNewPassword: 'resetPasswordNewPassword',
	Notification: 'notification',
	Credits: 'credits',
	PasswordProtected: 'enterPassword',
	EmailVerification: 'emailVerification',
	SentConfirmationEmail: 'sentConfirmationEmail',
	Welcome: 'welcome',
	NoPermissionsToPage: 'noPermissionsToPage',
}

export const NOTIFICATIONS = {
	Template: 'template',
	SiteOwner: 'siteowner',
	SignUp: 'register',
	ResetPasswordEmail: 'resetPasswordEmail',
	ResetPasswordNewPassword: 'resetPasswordNewPassword',
}

export enum PrivacyStatus {
	PUBLIC = 'PUBLIC',
	PRIVATE = 'PRIVATE',
	COMMUNITY = 'COMMUNITY',
}

export const CompTypes = {
	SocialAuth: 'SocialAuth',
	LoginButton: 'wysiwyg.viewer.components.LoginButton',
	LoginSocialBar: 'wysiwyg.viewer.components.LoginSocialBar',
}

export const AUTH_RESULT_REASON = {
	CANCELED: 'authentication canceled',
	ALREADY_LOGGED_IN: 'already logged in',
	SUCCESS: 'success',
}

export const ERROR_CODES = {
	PASSWORD_RESETED: '-19973',
	WRONG_AUTH_DETAILS: '-19976',
	ACCESS_DENID: '-19956',
	VALIDATION_ERROR: '-19988',
	WAITING_APPROVAL: '-19958',
	UNKNOWN_ACCOUNT: '-19999',
	WRONG_PASSWORD: '-17005',
	EXISTING_EMAIL_ACCOUNT: '-19995',
	CLIENT_AUTH_FORBIDDEN: '-19974',
	EMAIL_NOT_PROVIDED: '-18880',
	CAPTCHA_REQUIRED: '-19971',
	CAPTCHA_INVALID: '-19970',
	RESET_PASSWORD_TOKEN_EXPIRED: '-19972',
	NEW_RESET_PASSWORD_TOKEN_EXPIRED: 'EXPIRED_JWT_TOKEN',
	REQUEST_THROTTLED: '-19959',
	CODE_INVALID: 'EMAIL_VERIFICATION_REQUIRED',
	BAD_CODE: 'EMAIL_VERIFICATION_FAILED',
	SERVER_EXCEPTION: '-19901',
	AUTHENTICATION_FAILED: '-19976',
	UNIMPLEMENTED_FEATURE: 'UNIMPLEMENTED_FEATURE',
}

export const CAPTCHA_ERROR_CODES = [
	ERROR_CODES.CAPTCHA_REQUIRED,
	ERROR_CODES.CAPTCHA_INVALID,
	ERROR_CODES.REQUEST_THROTTLED,
]

export const VERIFICATION_CODE_ERROR_CODES = [ERROR_CODES.CODE_INVALID, ERROR_CODES.BAD_CODE]

export const LOGIN_ERROR_CODES = [
	ERROR_CODES.CLIENT_AUTH_FORBIDDEN,
	ERROR_CODES.PASSWORD_RESETED,
	ERROR_CODES.WRONG_AUTH_DETAILS,
	ERROR_CODES.ACCESS_DENID,
	ERROR_CODES.VALIDATION_ERROR,
	ERROR_CODES.WAITING_APPROVAL,
	ERROR_CODES.UNKNOWN_ACCOUNT,
	ERROR_CODES.WRONG_PASSWORD,
	...CAPTCHA_ERROR_CODES,
	...VERIFICATION_CODE_ERROR_CODES,
]

export const SIGN_UP_ERROR_CODES = [
	ERROR_CODES.CLIENT_AUTH_FORBIDDEN,
	ERROR_CODES.EXISTING_EMAIL_ACCOUNT,
	ERROR_CODES.VALIDATION_ERROR,
	ERROR_CODES.EMAIL_NOT_PROVIDED,
	ERROR_CODES.CODE_INVALID,
	...CAPTCHA_ERROR_CODES,
	...VERIFICATION_CODE_ERROR_CODES,
]

export const UNSUPPORTED_AGENTS_FOR_SOCIAL_AUTH = ['FBAV', 'FBAN', 'Instagram']

export const INVISIBLE_CAPTCHA_API_KEY = '6LdoPaUfAAAAAJphvHoUoOob7mx0KDlXyXlgrx5v'

export const TRACK_EVENTS = {
	CATEGORY: 'Site members',
	LABEL: 'Wix',
	ACTIONS: {
		LOGIN: {
			SUCCESS: 'Log in Success',
			SUBMIT: 'Log in Submit',
			FAIL: 'Log in Failure',
		},
		SIGNUP: {
			SUCCESS: 'Sign up Success',
			SUBMIT: 'Sign up Submit',
			FAIL: 'Sign up Failure',
		},
		LOGOUT: {
			FAIL: 'Log out failed',
		},
		SETTINGS: {
			FAIL: 'Querying site members settings failed',
		},
	},
}

export const getTrackEventParams = (eventAction: string, eventLabel = TRACK_EVENTS.LABEL) => {
	return {
		eventName: 'CustomEvent',
		params: {
			eventCategory: TRACK_EVENTS.CATEGORY,
			eventAction,
			eventLabel,
		},
	}
}

export const CAPTCHA_REQUIRED_RESPONSE = {
	message: `Recaptcha token is required (${ERROR_CODES.CAPTCHA_REQUIRED})`,
	details: {
		applicationError: {
			code: ERROR_CODES.CAPTCHA_REQUIRED,
			description: `Recaptcha token is required (${ERROR_CODES.CAPTCHA_REQUIRED})`,
		},
	},
}

export const newIAMResetPasswordTokenPrefix = 'ART'

export const idpConnectionIdToVendorNames: Record<string, string> = {
	'3ecad13f-52c3-483d-911f-31dbcf2a6d23': 'facebook',
	'0e6a50f5-b523-4e29-990d-f37fa2ffdd69': 'google',
}

type TRANSLATION_KEYS =
	| 'templateNotificationTitle'
	| 'templateNotificationMessage'
	| 'containerOk'
	| 'resetPasswordCheckEmailTitle'
	| 'resetPasswordCheckEmailText'
	| 'resetPasswordOk'
	| 'resetPasswordSuccessTitle'
	| 'passwordHasExpiredTitle'
	| 'passwordHasExpiredText'
	| 'passwordHasExpiredOk'
	| 'applySuccess1'
	| 'applySuccess2'

export const TRANSLATIONS: { [key in TRANSLATION_KEYS]: { namespace: string; key: string; default: string } } = {
	templateNotificationTitle: {
		namespace: 'siteMembersTranslations',
		key: 'siteMembersTranslations_TEMPLATE_NOTIFICATION_TITLE',
		default: 'Demo Mode',
	},
	templateNotificationMessage: {
		namespace: 'siteMembersTranslations',
		key: 'siteMembersTranslations_TEMPLATE_NOTIFICATION_MESSAGE',
		default: 'To make this template yours, start editing it.',
	},
	containerOk: { namespace: 'siteMembersTranslations', key: 'SMContainer_OK', default: 'OK' },
	resetPasswordCheckEmailTitle: {
		namespace: 'siteMembersTranslations',
		key: 'siteMembersTranslations_RESET_PASSWORD_CHECKEMAIL_TITLE',
		default: 'Please Check Your Email',
	},
	resetPasswordCheckEmailText: {
		namespace: 'siteMembersTranslations',
		key: 'siteMembersTranslations_RESET_PASSWORD_CHECKEMAIL_TEXT',
		default: 'We’ve emailed you a link to reset your password.',
	},
	resetPasswordOk: {
		namespace: 'siteMembersTranslations',
		key: 'siteMembersTranslations_Reset_Password_OK',
		default: 'Got It',
	},
	resetPasswordSuccessTitle: {
		namespace: 'siteMembersTranslations',
		key: 'siteMembersTranslations_Reset_Password_Sucess_Title',
		default: 'Your password has been changed.',
	},
	passwordHasExpiredTitle: {
		namespace: 'siteMembersTranslations',
		key: 'siteMembersTranslations_PASSWORD_HAS_EXPIRED_TITLE',
		default: 'Your link to create a new password has expired',
	},
	passwordHasExpiredText: {
		namespace: 'siteMembersTranslations',
		key: 'siteMembersTranslations_PASSWORD_HAS_EXPIRED_TEXT',
		default: 'To continue, resend a new link to your email.',
	},
	passwordHasExpiredOk: {
		namespace: 'siteMembersTranslations',
		key: 'siteMembersTranslations_PASSWORD_HAS_EXPIRED_OK',
		default: 'Resend Link',
	},
	applySuccess1: {
		namespace: 'siteMembersTranslations',
		key: 'SMApply_Success1',
		default: 'Success! Your member {0} request has been sent and is awaiting approval.',
	},
	applySuccess2: {
		namespace: 'siteMembersTranslations',
		key: 'SMApply_Success2',
		default: 'The site administrator will notify you via email ({0}) once your request has been approved.',
	},
}
